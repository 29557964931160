import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Collapse } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp, Close } from '@material-ui/icons';

import './style.scss';
import { setSupportDialog } from '../../actions/generalActions';
import whatsapp from '../../assets/whatsapp-support.png';
import email from '../../assets/email-support.png';
import phone from '../../assets/phone-support.png';
import Row from '../Elements/Flex/Row';
import Col from '../Elements/Flex/Col';

class NeedSupportModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openQuestion: 0,
    };
    this.supportData = [
      {
        question: 'Batch/time changes or any other queries/complaints',
        answer:
          'You can reach out to our LQ Champs Dedicated customer service team on the following:',
        contact: [
          {
            icon: phone,
            detail: '+91 808-080-9604 / 05 (10 am to 7 pm)',
            type: 'phone',
          },
          { icon: whatsapp, detail: '91-961-961-9361', type: 'whatsapp' },
          { icon: email, detail: 'lqchamps@logiqids.com', type: 'email' },
        ],
      },
      {
        question:
          'Query hasn’t been addressed within 24 hours of you raising it?',
        answer:
          'If you aren’t happy with the resolution provided by the above contacts, reach out to our <b>Customer Success Head- Nikita</b>.',
        contact: [
          {
            icon: phone,
            detail: '+91-9321441223 (Mon - Fri from 10 am - 7pm)',
            type: 'phone',
          },
          { icon: whatsapp, detail: '91-9321441223', type: 'whatsapp' },
          { icon: email, detail: 'nikita@logiqids.com', type: 'email' },
        ],
      },
    ];
  }

  render() {
    const { openQuestion } = this.state;

    return (
      <div className='outer-container'>
        <Col className='inner-container'>
          <Row
            className='close-button'
            onClick={() => this.props.setSupportDialog(false)}
          >
            <Close />
          </Row>
          <Col className='header-container align-start'>
            <div className='header-title'>Support</div>
            <div className='header-subtitle'>How can we help you?</div>
            <div className='header-detail'>
              We promise to resolve your queries on priority. Please use the
              following dedicated communication channels:
            </div>
          </Col>
          <Col className='data-container space-between'>
            <Col className='questions-container'>
              <div className='line' />
              {this.supportData.map((data, index) => (
                <Col className='question-inner-container'>
                  <Row
                    className='question space-between'
                    onClick={() => {
                      if (openQuestion == index)
                        this.setState({ openQuestion: null });
                      else this.setState({ openQuestion: index });
                    }}
                  >
                    <div>{data.question}</div>
                    {openQuestion == index ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </Row>
                  <Collapse in={openQuestion == index}>
                    <Col className='align-start answer-container'>
                      <div
                        className='answer'
                        dangerouslySetInnerHTML={{
                          __html: data.answer,
                        }}
                      />
                      <Row className='contact-container'>
                        {data?.contact?.map((contactData) => (
                          <Row
                            className='contact-inner-container'
                            onClick={() => {
                              if (contactData.type == 'email')
                                window.location.assign(
                                  `mailto:${contactData.detail}`
                                );
                            }}
                          >
                            <img className='icon' src={contactData.icon} />
                            <div className='contact'>{contactData.detail}</div>
                          </Row>
                        ))}
                      </Row>
                    </Col>
                  </Collapse>
                  <div className='line' />
                </Col>
              ))}
            </Col>
            <div className='footer-text'>
              We are always open to hear any feedback you may have and assure
              you that we do take up every feedback very sincerely.
            </div>
          </Col>
        </Col>
      </div>
    );
  }
}

export default connect(null, { setSupportDialog })(NeedSupportModal);
